import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { H6 } from '../Heading/Heading';
import IconCard from '../IconCard/IconCard';
import IconClose from '../IconClose/IconClose';
import IconProfileCard from '../IconProfileCard/IconProfileCard';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
// import IconCollection from '../IconCollection/IconCollection';

import css from './FieldMultiSelectCustomField.module.css';

const FieldMultiSelectCustomField = props => {
    let {
        id,
        name,
        type,
        label,
        classes,
        data = [],
        onChange,
        validate,
        initialval,
        placeholder,
        classNameAdd,
        inputBoxSkill,
        showMorethan3 = false,
        OptionsWithCustomField = false,
        showIButton = true,
        addAlldata = false,
        showCustomError = false,
        customError = 'This field is required.',
        filterWork = false,
        toolTipData = false,
        showOnlyTwo=false
        // intl,
        // addCustomField = true 
    } = props || {};

    const [otherData, setOtherData] = useState('');

    const [updateddata, setUpdatedData] = useState([])

    const [inputText, setInputText] = useState('');

    const [gotValue, setGotvalue] = useState(false);

    const [currFocus, setCurrFocus] = useState(false);

    const [err, setErr] = useState('');

    const [validation, setValidation] = useState(validate);

    const [notFocus, setNotfocus] = useState(false);

    const [visited, setVisited] = useState(0);

    useEffect(() => {
        if (initialval && !gotValue && initialval[0] !== '') {
            setUpdatedData(initialval);
            setGotvalue(true);
        }
    }, [initialval]);


    const handleChange = (e) => {
        if(updateddata && updateddata.length >1 && showOnlyTwo){
        }else{
            setInputText(e.target.value);
        }
        
    }

    const handleClick = (element) => {
        if (element !== 'custom') {
            if (!updateddata?.includes(element)) {
                if ((typeof element != 'object' && !element.hasOwnProperty('label')) && data && data.length && filterWork) {
                    const filteredData = data?.filter(({ label }) => label === element);
                    if (filteredData && filteredData.length) {
                        if (!updateddata?.includes(filteredData[0])) {
                            element = filteredData[0]
                        } else {
                            setInputText('');
                            return null
                        }
                    }
                }
                setUpdatedData((pre) => {
                    if (pre) {
                        return [...pre, element];
                    } else {
                        return [element];
                    }
                });
            }
            setInputText('');
        } else if (element === 'custom') {
            setInputText('custom');
        }
        setCurrFocus(false);
    }

    useMemo(() => {
        if (updateddata?.length > 0) {
            setErr("");
            setValidation(false);

        }
        else if (visited > 0) {
            setErr(`You need to add ${name}`);
            setValidation(true);
        }
        onChange(updateddata);
    }, [updateddata]);

    const handleDelete = (element) => {
        updateddata?.forEach((ele, idx) => {
            if (ele === element) {
                updateddata?.splice(idx, 1);
                setUpdatedData([...updateddata]);
            } else if (ele && ele.label) {
                if (ele?.label == element) {
                    updateddata?.splice(idx, 1);
                    setUpdatedData([...updateddata]);
                } else {
                    setUpdatedData([...updateddata]);
                }
            }
            else {
                setUpdatedData([...updateddata]);
            }
        });
    }

    // useEffect(() => {
    //     if (name === 'accorlads' && currFocus) {
    //         setInputText('custom');
    //     }
    // }, [currFocus]);

    const filteredData = data?.filter(e => e.value.toLowerCase().indexOf(inputText.toLowerCase()) !== -1);

    return (
        <OutsideClickHandler className={css.inputForm} onOutsideClick={() => {
            setCurrFocus(false);
            setInputText('');
            visited > 0 ? validation ? setErr(`You need to add ${name}`) : null : null;
            setNotfocus(true);
        }}>

            <label>
                <span className={css.iconHover}>{label}
                    {showIButton ?
                        <span data-tooltip={OptionsWithCustomField ? "Choose/Add one or more" : data.length > 0 ? "Add your own custom tool or choose one or more. " : "Choose or Add one or more"}>
                            &nbsp;<IconCard brand="info" />
                        </span> :
                        <div className={currFocus ? css.yesTool : css.tooltip}> &nbsp;<IconCard brand="info" />
                            <span className={css.tooltiptext}>{toolTipData ? 'Choose all that apply to the above skills you have under the hood.' : 'Don’t see your role, add a custom one.'} </span>
                        </div>}



                </span>
            </label>
            <div className={css.inputAndValue}>

                {/* show selected */}

                {updateddata && updateddata?.map((ele, idx) => {
                    return (showMorethan3 && ele && (typeof ele == 'string')
                        ? idx < 3
                            ? <span cla="true" key={'showMorethan3' + idx} className={css.inputData}>
                                {typeof ele == 'object' ? (ele?.label.charAt(0)?.toUpperCase() + ele?.label.slice(1)) : (ele?.charAt(0)?.toUpperCase() + ele?.slice(1))}
                                <span onClick={() => handleDelete(typeof ele == 'object' ? ele?.label : ele)} >
                                    <IconClose />
                                </span>
                            </span>
                            : null
                        : <span cla="true" key={'showMorethan3' + idx} className={css.inputData}>
                            {typeof ele == 'object' ? (ele?.label.charAt(0)?.toUpperCase() + ele?.label.slice(1)) : (ele?.charAt(0)?.toUpperCase() + ele?.slice(1))}
                            <span onClick={() => handleDelete(typeof ele == 'object' ? ele?.label : ele)} >
                                {(updateddata && updateddata.length > 1) ? <IconClose /> : null}
                            </span>
                        </span>);
                })}

                <div className={css.inputWrapper}>
                    <input
                        type={type}
                        name={name}
                        id={id}
                        onChange={(e) => handleChange(e)}
                        value={inputText}
                        placeholder={placeholder}
                        onFocus={() => {
                            if(updateddata && updateddata.length >1 && showOnlyTwo){
                            }else{
                                setCurrFocus(true);
                            setVisited((pre) => pre + 1);
                            }
                        }}
                        className={visited > 0 ? validation && notFocus ? css.redBorder : null : null}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && inputText && inputText !== '') {
                                handleClick(inputText)
                            }
                        }}
                    />
                </div>
            </div>

            {err && <div>
                <H6 className={css.error}>{showCustomError ? customError : err}</H6>
            </div>}
            {/* list */}
            {data && data.length
                ? <div>
                    {inputText !== ''
                        ? <div className={css.inputDropdownList}>
                            {inputText !== ''
                                ? filteredData && filteredData.length
                                    ? filteredData.map((ele, idx) => {
                                        return <p key={idx + '_' + ele.value} onClick={() => handleClick(ele.value)}>{ele.value}</p>
                                    })
                                    : <p key={'custom value'} onClick={() => handleClick(inputText)}>Add as custom</p>
                                : null}
                            {inputText === 'custom'
                                ? <div>
                                    <div className={css.otherInput}>
                                        <input
                                            type='text'
                                            name='CustomData'
                                            label={'Custom'}
                                            placeholder={'Type here...'}
                                            className={classes}
                                            value={otherData}
                                            onChange={(e) => setOtherData(e.target.value)}
                                            maxLength={35}
                                            autoFocus={true}
                                        />
                                    </div>
                                    <div className={classNames(css.addButton, inputBoxSkill && css.inputBoxSkill, classNameAdd)}>
                                        <div onClick={(e) => {
                                            e.preventDefault();
                                            setUpdatedData((pre) => {
                                                if (!pre.includes(otherData))
                                                    return [...pre, otherData];
                                                else
                                                    return [...pre];
                                            });
                                            setInputText('');
                                            setOtherData('');
                                        }}>
                                            <IconProfileCard type='blueaddicon' />
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                        : <div className={currFocus ? css.focusDropdownlist : null}>
                            {currFocus
                                ? <>
                                    {/* {addCustomField ? <p onClick={() => handleClick("custom")}>Custom</p> : null} */}
                                    {data?.filter((e) => !updateddata?.includes(e.value))?.map((ele, idx) => {
                                        return (<p key={ele.value + '-' + idx} onClick={() => { handleClick(addAlldata ? ele : ele.value) }}>
                                            {ele.value}
                                        </p>);
                                    })}
                                </>
                                : null}
                        </div>}
                </div>
                : inputText !== ''
                    ? <div className={css.inputDropdownList}>
                        <p key={'custom value'} onClick={() => handleClick(inputText)}>Add as custom</p>
                    </div>
                    : null}

        </OutsideClickHandler>
    );
}

export default FieldMultiSelectCustomField;